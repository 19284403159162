import React from "react";
import { select_raw_corpus } from "../api/services";
import CorpusItem from "./CorpusItem";

const updateHash = (highlight) => {
  document.location.hash = `highlight-${highlight.id}`;
};

const uncheckelement = element_id => {
  try{
    const element = document.getElementById(element_id);
    element.checked = false;
    element.value = "off";
  }catch(err){
    console.log(err);
  }
}

const checkelement = element_id => {
  try{
    const element = document.getElementById(element_id);
    element.checked = true;
    element.value = "on";
  }catch(err){
    console.log(err);
  }
}

const Sidebar = ({ highlights, toggleDocument, resetHighlights, bylaw_authed }) => {
  highlights.sort((a,b) => {
    if (a.position.pageNumber === b.position.pageNumber) {
      return a.position.boundingRect.y1 - b.position.boundingRect.y1;
    }
    return a.position.pageNumber - b.position.pageNumber;
  });

  const togglecorpus = async (e, highlight) => {
    const corpus_id = highlight.text_id;
    const irrelevant_switch_id = `irrelevant-${highlight.text_id}`;
    const corpus_switch_id = `corpus-${highlight.text_id}`;
    if (e.target.value === "off"){ // Uncheck
      try{
        await select_raw_corpus(corpus_id, 1);
        checkelement(corpus_switch_id);
        uncheckelement(irrelevant_switch_id);
      }catch(err){console.log(err);}
    }
    else{
      try{
        await select_raw_corpus(corpus_id, 0);
        uncheckelement(corpus_switch_id);
        uncheckelement(irrelevant_switch_id);
      }catch(err){console.log(err);}
    }
  }


  const toggleirrelevance = async (e, highlight) => {
    const corpus_id = highlight.text_id;
    const irrelevant_switch_id = `irrelevant-${highlight.text_id}`;
    const corpus_switch_id = `corpus-${highlight.text_id}`;
    if (e.target.value === "off"){ // Uncheck
      try{
        await select_raw_corpus(corpus_id, 2);
        checkelement(irrelevant_switch_id);
        uncheckelement(corpus_switch_id);
      }catch(err){console.log(err);}
    }
    else{
      try{
        await select_raw_corpus(corpus_id, 0);
        uncheckelement(corpus_switch_id);
        uncheckelement(irrelevant_switch_id);
      }catch(err){console.log(err);}
    }
  };

  return (
    <div>

      {
        highlights.length > 0 &&
        (<div>
        <p className="text-black font-weight-bold mt-4">Relevant text from HOA documents</p>

        {highlights.map((highlight, index) => {
          if (highlight.selected === 1 || bylaw_authed)
          return (
            <CorpusItem
              key={highlight.id}
              highlight={highlight}
              index={index}
              togglecorpus={togglecorpus}
              toggleDocument={toggleDocument}
              updateHash={updateHash}
              toggleirrelevance={toggleirrelevance}
              bylaw_authed={bylaw_authed}
            />
          )
        })}
      </div>)
    }

    </div>
  );
}

export default Sidebar;
